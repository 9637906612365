// GoogleMapで定義されているタイルサイズ
// 参照: https://developers.google.com/maps/documentation/javascript/coordinates#pixel-coordinates
const TILE_SIZE = 256;

// Webメルカトル投影を使用して、緯度経度をワールド座標（ピクセル）に変換
// 参照: https://developers.google.com/maps/documentation/javascript/examples/map-coordinates
const project = (latLng: {
  lat: number;
  lng: number;
}): {
  x: number;
  y: number;
} => {
  let siny = Math.sin((latLng.lat * Math.PI) / 180);

  // Truncating to 0.9999 effectively limits latitude to 89.189. This is
  // about a third of a tile past the edge of the world tile.
  siny = Math.min(Math.max(siny, -0.9999), 0.9999);

  const x = TILE_SIZE * (0.5 + latLng.lng / 360);
  const y =
    TILE_SIZE * (0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI));

  return { x, y };
};

// Webメルカトル投影の逆変換
const unproject = (point: {
  x: number;
  y: number;
}): { lat: number; lng: number } => {
  const temp = (0.5 - point.y / TILE_SIZE) * 4 * Math.PI;
  const siny = (Math.exp(temp) - 1) / (Math.exp(temp) + 1);

  const lat = Math.asin(siny) * (180 / Math.PI);
  const lng = (point.x / TILE_SIZE - 0.5) * 360;

  return { lat, lng };
};

// offsetPixelsだけ平行移動したときの緯度経度を取得
export const getLatLngFromOffset = (
  latlng: { lat: number; lng: number },
  zoom: number,
  xOffsetPixels: number = 0,
  yOffsetPixels: number = 0
): { lat: number; lng: number } => {
  const scale = 1 << zoom;

  // ワールド座標を取得
  const worldCoord = project(latlng);

  // xOffsetPixelsだけ右に移動
  const x = worldCoord.x + xOffsetPixels / scale;

  // yOffsetPixelsだけ下に移動
  const y = worldCoord.y + yOffsetPixels / scale;

  // 新しいワールド座標から緯度経度を取得
  return unproject({ x, y });
};

// ControlPanelの占有領域を考慮して、中心座標をずらす
export const culculateShiftedCenter = (
  center: { lat: number; lng: number },
  zoom: number
): { lat: number; lng: number } => {
  const controlPanelEastX = 460; // ControlPanel右辺のx座標(px)
  const shiftX = controlPanelEastX / 2; // X方向にずらす分
  const shiftedCenter = getLatLngFromOffset(center, zoom, -1 * shiftX, 0);

  return shiftedCenter;
};
