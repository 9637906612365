import { useEffect, useMemo, useRef } from "react";

import { Marker } from "./compenents/Marker";
import type { Me } from "./hooks/useMeQuery";
import { useTrackingInventoryQuery } from "./hooks/useTrackingInventoryQuery";
import { useTeamIdAtom } from "./hooks/useTeamIdAtom";
import { StatusIcon } from "./compenents/StatusIcon";
import { isActiveStatus } from "./functions/utils";
import { culculateShiftedCenter } from "./functions/map";
import { useMapAtom } from "./hooks/useMapAtom";

const GOOGLE_MAP_ID = "e1cd53413efc65b2";
const DEFAULT_CENTER = { lat: 43.0592209, lng: 141.3233896 }; // 札幌保健所
const DEFAULT_ZOOM = 13;

export function MapView({ me }: { me: Partial<Me> }) {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useMapAtom();
  const { data: trackingInventory } = useTrackingInventoryQuery();
  const [teamId, setTeamId] = useTeamIdAtom();

  const mapOptions: google.maps.MapOptions = useMemo(() => {
    const center = {
      lat: me?.latitude || DEFAULT_CENTER.lat,
      lng: me?.longitude || DEFAULT_CENTER.lng,
    };
    const siftedCenter = culculateShiftedCenter(center, DEFAULT_ZOOM);
    return {
      mapId: GOOGLE_MAP_ID,
      center: siftedCenter,
      zoom: DEFAULT_ZOOM,
      mapTypeControl: false,
      fullscreenControl: false,
    };
  }, [me]);

  useEffect(() => {
    if (ref.current) {
      setMap(new window.google.maps.Map(ref.current, mapOptions));
    }
  }, [mapOptions, setMap]);

  return (
    <div ref={ref} className="h-screen w-screen">
      {trackingInventory
        ?.map((item, i) => ({ ...item, index: i + 1 }))
        .map(
          (item) =>
            item.latitude &&
            item.longitude && (
              <Marker
                key={item.index}
                map={map}
                position={{ lat: item.latitude, lng: item.longitude }}
                zIndex={item.device_id === teamId ? 1 : 0}
                onClick={() => setTeamId(item.device_id ?? undefined)}
              >
                <div className="absolute bottom-2 left-0">
                  {/* Popup*/}
                  <div
                    className={`absolute top-0 left-0 transform -translate-x-1/2 -translate-y-full p-1 rounded flex gap-1 items-center justify-center ${
                      teamId === item.device_id
                        ? "w-28 border-4"
                        : "w-20 border-2"
                    } ${
                      item.is_difficult_case
                        ? "bg-active border-red-800 text-white"
                        : isActiveStatus(item.status)
                        ? "bg-white border-active"
                        : "bg-white border-inactive"
                    }`}
                  >
                    <div className="min-w-max my-auto">
                      <StatusIcon
                        type={
                          item.is_difficult_case
                            ? "danger"
                            : isActiveStatus(item.status)
                            ? "active"
                            : "inactive"
                        }
                      />
                    </div>
                    <div
                      className={`${
                        teamId === item.device_id ? "text-base" : "text-xs"
                      } font-bold truncate`}
                    >
                      {item.team_name ?? `救急隊${item.index}`}
                    </div>
                  </div>

                  {/* Popup Anchor */}
                  <div
                    className={`absolute top-0 left-0 transform -translate-x-1/2 w-0 h-0 border-t-8 border-x-8 border-x-transparent ${
                      item.is_difficult_case
                        ? "border-t-red-800"
                        : isActiveStatus(item.status)
                        ? "border-t-active"
                        : "border-t-inactive"
                    }`}
                  />
                </div>
              </Marker>
            )
        )}
    </div>
  );
}
