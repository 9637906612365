import { atom, useAtom, useAtomValue } from "jotai";
import { culculateShiftedCenter } from "../functions/map";

const mapAtom = atom<google.maps.Map | undefined>(undefined);

export const useMapAtom = () => {
  return useAtom(mapAtom);
};

export const useMoveCamera = () => {
  const map = useAtomValue(mapAtom);
  return (latlng: { lat: number; lng: number }, zoom: number) => {
    if (!map) return;
    const center = culculateShiftedCenter(latlng, zoom);
    map.moveCamera({ center, zoom });
  };
};
