import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

export const StatusIcon = ({
  type,
}: {
  type: "active" | "inactive" | "danger";
}) => {
  return (
    <div
      className={`w-3.5 h-3.5 flex items-center justify-center rounded-full border border-slate-50 box-content ${
        type === "inactive" ? "bg-inactive " : "bg-active"
      }`}
    >
      {type === "danger" && (
        <PriorityHighIcon fontSize="inherit" className="text-white" />
      )}
    </div>
  );
};
