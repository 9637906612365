import { useQuery } from "@tanstack/react-query";

import { fetchRestApi } from "../functions/utils";
import { useMeQuery } from "./useMeQuery";

export type Status =
  | "DISPATCH"
  | "SITE_ARRIVAL"
  | "SITE_DISPATCH"
  | "HOSPITAL_ARRIVAL"
  | "NOT_APPLICABLE";

export type BiologicalSex = "MALE" | "FEMALE" | "UNKNOWN";

export type TrackingInventory = {
  device_id: string;
  section_id: string;
  fire_station_id: string;
  team_name: string;
  patient_id: string;
  jian_number: string;
  jian_summary: string;
  age: number | null;
  biological_sex: BiologicalSex | null;
  chief_complaint: string;
  vital_sign: {
    jcs: string;
    gcs_e: number | null;
    gcs_v: number | null;
    gcs_m: number | null;
    respiration_rate: number | null;
    pulse_rate: number | null;
    systolic_blood_pressure: number | null;
    diastolic_blood_pressure: number | null;
    spo2: number | null;
    spo2_after_oxygen_administration: number | null;
    oxygen_route: string;
    oxygen_flow: number | null;
    body_temperature: number | null;
  };
  status: Status;
  inquiry_count: number;
  latitude: number | null;
  longitude: number | null;
  timestamp: string;
  is_difficult_case: boolean;
};

export const useTrackingInventoryQuery = () => {
  const { data: me } = useMeQuery();
  const section_id = me?.section_id;

  return useQuery<Array<Partial<TrackingInventory>>>({
    queryKey: ["tracking_inventory", section_id],
    queryFn: async () => fetchRestApi(`/tracking-inventory/${section_id}`),
    enabled: !!section_id,
    refetchInterval: 1000 * 10, // 10秒
    refetchIntervalInBackground: true,
  });
};
