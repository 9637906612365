type ButtonProps = {
  label: string;
  color: "primary" | "secondary" | "error";
  onClick?: () => void;
  icon?: React.ReactNode;
  className?: string;
  disabled?: boolean;
};

export const Button = ({
  label,
  color,
  onClick,
  icon,
  disabled,
}: ButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={`flex items-center justify-center gap-1 rounded text-sm font-bold px-1.5 py-0.5 hover:opacity-70 disabled:opacity-100 ${
        color === "primary"
          ? "bg-blue-400"
          : color === "error"
          ? "bg-rose-300"
          : "bg-slate-300"
      }`}
      disabled={disabled}
    >
      {icon}
      {label}
    </button>
  );
};
