import React from "react";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import "./hideForgotPassword.css";
import { Spinner } from "./compenents/Spinner";
import { MapView } from "./MapView";
import { ControlPanel } from "./ControlPanel";
import { AccountPopover } from "./AccountPopover";
import { useMeQuery } from "./hooks/useMeQuery";

Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: false,
    signUpVerificationMethod: "code",
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});

const render = (status: Status) => {
  return (
    <div className="h-screen w-screen flex justify-center items-center gap-4 p-8">
      {status === Status.FAILURE && (
        <p className="text-red-700 text-center">地図を表示できませんでした</p>
      )}
      {status === Status.LOADING && (
        <>
          <Spinner />
          <p className="text-slate-700">地図を読み込み中です</p>
        </>
      )}
    </div>
  );
};

function MapApp() {
  const { data: me, isLoading, isStale, isError } = useMeQuery();
  const { signOut } = useAuthenticator();

  const signOutAndInvalidate = () => {
    signOut();
    queryClient.invalidateQueries({
      refetchType: "none",
    });
  };

  if (isError) {
    return (
      <div className="h-screen w-screen flex flex-col justify-center items-center gap-4 p-8">
        <p className="text-red-700">
          アカウントの読み込みに失敗しました。再度ログインしてください。もしくはアカウントが当アプリに対応していない可能性があります。
        </p>
        <button
          onClick={signOutAndInvalidate}
          className="p-3 rounded bg-slate-600 text-white font-bold"
        >
          ログイン画面に戻る
        </button>
      </div>
    );
  }

  if (isLoading || isStale || me === undefined) {
    return (
      <div className="h-screen w-screen flex justify-center items-center gap-4 p-8">
        <Spinner />
        <p className="text-slate-700">データを読み込み中です</p>
      </div>
    );
  }

  return (
    <Wrapper
      apiKey={process.env.REACT_APP_MAP_API_KEY ?? ""}
      libraries={["marker"]}
      render={render}
    >
      <MapView me={me} />
      <AccountPopover me={me} signOut={signOutAndInvalidate} />
      <ControlPanel />
    </Wrapper>
  );
}

function App() {
  console.log("render App");
  return (
    <QueryClientProvider client={queryClient}>
      <Authenticator hideSignUp={true}>
        <MapApp />
      </Authenticator>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
