import { useQuery } from "@tanstack/react-query";

import { fetchRestApi } from "../functions/utils";
export type Me = {
  display_name: string;
  latitude: number | null;
  longitude: number | null;
  section_id: string;
};

export const useMeQuery = () => {
  return useQuery<Partial<Me>>({
    queryKey: ["me"],
    queryFn: async () => fetchRestApi("/me"),
  });
};
