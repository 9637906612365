import { useEffect, useRef } from "react";
import { createRoot, Root } from "react-dom/client";

type MarkerProps = {
  map: google.maps.Map | null | undefined;
  children: React.ReactNode;
  position: google.maps.LatLngLiteral | google.maps.LatLng | null | undefined;
  zIndex?: number;
  onClick?: () => void;
};

export const Marker = ({
  map,
  children,
  position,
  zIndex,
  onClick,
}: MarkerProps) => {
  const markerRef = useRef<
    google.maps.marker.AdvancedMarkerElement | undefined
  >(undefined);
  const rootRef = useRef<Root | undefined>(undefined);

  useEffect(() => {
    const container = document.createElement("div");
    rootRef.current = createRoot(container);
    markerRef.current = new google.maps.marker.AdvancedMarkerElement({
      content: container,
    });

    return () => {
      container.remove();
      rootRef.current = undefined;
      markerRef.current = undefined;
    };
  }, []);

  useEffect(() => {
    if (rootRef.current && markerRef.current) {
      rootRef.current.render(children);
      markerRef.current.position = position;
      markerRef.current.map = map;
    }
  }, [map, position, children]);

  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.zIndex = zIndex;
    }
  }, [zIndex]);

  useEffect(() => {
    let listener: google.maps.MapsEventListener;
    if (markerRef.current && onClick) {
      listener = markerRef.current.addListener("click", onClick);
    }
    return () => {
      if (listener) {
        listener.remove();
      }
    };
  }, [onClick]);

  return null;
};
